import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';

const getStep = (step) => {
  if (step.length === 1) {
    return '0' + step;
  }

  return step;
};

const FeatureCard = ({ image, imageAlt, step, title, description, button, imageHeight = 160 }) => {
  const withStep = typeof step === 'number';
  const { t } = useTranslation();

  return (
    <Grid.Container py={32} px={24}>
      <Grid.Row>
        {image && (
          <Grid.Col height={imageHeight} display="flex" alignItems="center" px={16} py={8}>
            {/*TODO image and imageAlt can be moved to separate object image = {src: '', alt: ''}*/}
            <ImageCore
              src={image}
              alt={imageAlt || title}
              maxHeight="100%"
              imgStyle={{ objectFit: 'contain', width: 'auto', maxWidth: '100%' }} // to fix image alignment on safari
            />
          </Grid.Col>
        )}

        {withStep && (
          <Grid.Col mt={16} mb={8}>
            <Text.Body3Strong textAlign="left" lineHeight={'20px'} color="primary">
              {t('common:step', { stepNumber: getStep(String(step)) })}
            </Text.Body3Strong>
          </Grid.Col>
        )}

        <Grid.Col mt={withStep ? 0 : 24} mb={24}>
          <Text.Subheading2 textAlign="left">{title}</Text.Subheading2>
        </Grid.Col>

        <Grid.Col>
          <Text.Body2 as="div" textAlign="left" whiteSpace="pre-line">
            {description}
          </Text.Body2>
        </Grid.Col>

        {button && (
          <Grid.Col mt={16}>
            <LinkWrapper link={button?.link}>
              <Button
                fullWidth={!button.image}
                onClick={button.handleOnClick}
                variant={button.image ? 'text' : 'default'}
              >
                {button.image ? (
                  <ImageCore src={button.image.src} alt={button.image.alt} height={48} />
                ) : (
                  button.title
                )}
              </Button>
            </LinkWrapper>
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid.Container>
  );
};

export default FeatureCard;
